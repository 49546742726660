<template>
    <div class="currentRegulation">
        <div class="sect-container">
            <div class="selectImg"  v-if="imgurl">
                <upload-img :files="imgFiles" v-if="imgurl"  @imgList="afterUpload" />
                <img :src="imgurl" alt="">
            </div>
            <img src="@/assets/img/current-regulation.png" class="defultImg" v-else>
            <div class="upload-btn" >
                <template v-if="!imgurl">
                    <upload-img :files="imgFiles" v-if="!imgurl"  @imgList="afterUpload" />
                    <i class="icon"></i>
                    <span class="txt">添加图片</span>
                </template>
                <p v-else class="txt comfirm" @click="comfrim">确定</p>
            </div>
        </div>
    </div>
</template>

<script>
import uploadImg from '@/components/selfUploadImg'
import { surveyAdd } from "@/api/user";
export default {
    components:{
        uploadImg
    },
    data () {
        return {
            imgurl:"",
            imgFiles:[]
            // detultImg:require("")
        }
    },
    created () {
        document.title = this.$t("currentRegulation.name")
    },
    methods: {
        afterUpload(e){
            this.imgurl = e[0].url;
        },
        comfrim(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            surveyAdd({
                imgUrls:[this.imgurl]
            }).then(res =>{
                toast.clear()
                if(res.data.errorCode == 0){
                    this.$toast("操作成功!");
                    this.$router.go(-1);
                    setTimeout(function() {
                        // 安卓
                        document.addEventListener('WeixinJSBridgeReady', function() {
                        WeixinJSBridge.call('closeWindow')
                        }, false)

                        // IOS
                        WeixinJSBridge.call('closeWindow')
                    }, 10)
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.currentRegulation{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .sect-container{
        img{
            
        }
        .defultImg{
            width: 10rem;
            height: 9.6rem;
        }
        .selectImg{
            position: relative;
              /deep/.van-uploader{
                opacity: 0;
                width: 100%; 
                height: 100%;
                position: absolute;
                .upload-cover,.upload-cover,.van-uploader__input-wrapper,.van-uploader__wrapper{
                    width: 100%;
                    height: 100%;
                }
            }
            img{
                height: auto;
                width: auto;
                max-width: 12rem;
                max-height: 14rem;
                margin: 0 auto;
                display: block;
            }
        }
        .upload-btn{
            width: 12rem;
            height: 2rem;
            margin: 0 auto;
            margin-top: 1.8rem;
            background: linear-gradient(0deg, #499FFF 0%, #49A5FF 52%, #49C1FF 100%);
            border-radius: 1rem;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            /deep/.van-uploader{
                opacity: 0;
                width: 100%; 
                height: 100%;
                position: absolute;
                .upload-cover{
                    width: 12rem;
                    height: 2rem;
                }
            }
            .icon{
                width: 0.84rem;
                height: 0.84rem;
                margin-right: 0.3rem;
                background: url("~@/assets/img/add-icon.png") no-repeat;
                background-size: 100% 100%;
            }
            .txt{
                font-size: 0.72rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
            }
            .comfirm{
                width: 100%;
                text-align: center;
                height: 2rem;
                line-height: 2rem;
            }
        }
    }
}
</style>